import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LogTheme from './LogTheme';
import { Typography, Box, Card, CardContent, Button } from '@mui/material';

function Blog() {
  const [logs, setLogs] = useState([]);
  const [isUserIdLoaded, setIsUserIdLoaded] = useState(false);
  const [sortOrder, setSortOrder] = useState('newest'); // State-variabel för sorteringsordningen

  console.log('Loggar: ', logs);
  const { userId, forceUpdate } = useAuth();
  console.log('userId in Blog.js:', userId);

  useEffect(() => {
    if (userId) {
      setIsUserIdLoaded(true);

      // Hämta loggar för den aktuella användaren
      console.log('Sending userId:', userId);
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/logs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Id': userId,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Sortera loggarna baserat på sorteringsordningen
          const sortedLogs = data.sort((a, b) => {
            if (sortOrder === 'newest') {
              return new Date(b.departureDateTime) - new Date(a.departureDateTime);
            } else {
              return new Date(a.departureDateTime) - new Date(b.departureDateTime);
            }
          });
          setLogs(sortedLogs);
        })
        .catch((error) => console.error('Error fetching logs:', error));
    } else {
      // Rensa logs-staten när userId är null
      setLogs([]);
    }
  }, [userId, forceUpdate, sortOrder]); // Lägg till sortOrder som en beroende

  // Funktion för att växla sorteringsordningen
  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'newest' ? 'oldest' : 'newest'));
  };

  return (
    <LogTheme>
      <Box padding={2}>
        <Typography variant="h1">Mina loggar</Typography>
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button component={Link} to="/createlog" variant="contained" color="primary">
            Ny logg
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button onClick={toggleSortOrder} variant="contained" color="secondary">
            SORTERA {sortOrder === 'newest' ? '↓' : '↑'}
          </Button>
        </Box>
        {Array.isArray(logs) && logs.map(log => (
          <Card key={log._id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h4">{log.title}</Typography>
              <Typography variant="h6">{new Date(log.departureDateTime).toLocaleDateString('sv-SE')}</Typography>
              <Button component={Link} to={`/showlog/${log._id}`} variant="contained" color="primary">
                Visa
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
    </LogTheme>
  );
}

export default Blog;