import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import LogTheme from './LogTheme';

const ShowServiceLog = () => {
  const { id } = useParams();
  const { userId } = useAuth();
  const [serviceLog, setServiceLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authorizationError, setAuthorizationError] = useState(false);
  const [zoomedImageIndex, setZoomedImageIndex] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/servicelog/${id}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        if (data.userId !== userId) {
          setAuthorizationError(true);
        } else {
          // Se till att images är en array
          if (typeof data.images === 'string') {
            data.images = JSON.parse(data.images);
          }
          setServiceLog(data);
        }
        setLoading(false);
      });
  }, [id, userId]);

  if (loading) {
    return <div>Laddar...</div>;
  }

  if (authorizationError) {
    return <div>Du har inte behörighet att se denna servicelogg.</div>;
  }

  if (!serviceLog) {
    return <div>Serviceloggen kunde inte hämtas.</div>;
  }

  return (
    <LogTheme>
      <Box padding={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h1">Service</Typography>
          <Button component={Link} to={`/updateservicelog/${id}`} variant="contained" color="primary">
            Ändra
          </Button>
        </Box>
        <Card sx={{ padding: 4 }}>
          <CardContent>
            <Typography variant="h2">{serviceLog.title}</Typography>
            <Typography variant="h4">{new Date(serviceLog.date).toLocaleDateString('sv-SE')}</Typography>
            <Typography variant="h4">Båtens namn/modell: {serviceLog.boatNameModel}</Typography>
            <Typography variant="h4">Motor/typ: {serviceLog.engineType}</Typography>
            <Typography variant="h4">Serviceperson eller företag: {serviceLog.servicePersonOrCompany}</Typography>
            <Typography variant="h4">Typ av service/underhåll: {serviceLog.serviceType}</Typography>
            {serviceLog.engineService && (
              <Typography variant="h4">Motorservice: {serviceLog.engineService}</Typography>
            )}
            {serviceLog.sailAndRig && (
              <Typography variant="h4">Segel och rigg: {serviceLog.sailAndRig}</Typography>
            )}
            {serviceLog.electronicsAndNavigation && (
              <Typography variant="h4">Elektronik och navigationssystem: {serviceLog.electronicsAndNavigation}</Typography>
            )}
            {serviceLog.hullAndDeck && (
              <Typography variant="h4">Skrov och däck: {serviceLog.hullAndDeck}</Typography>
            )}
            {serviceLog.rudderPropellerAndDrive && (
              <Typography variant="h4">Roder, propeller, och drev: {serviceLog.rudderPropellerAndDrive}</Typography>
            )}
            {serviceLog.fuelSystem && (
              <Typography variant="h4">Bränslesystem: {serviceLog.fuelSystem}</Typography>
            )}
            {serviceLog.batteryAndElectricalSystem && (
              <Typography variant="h4">Batteri och elsystem: {serviceLog.batteryAndElectricalSystem}</Typography>
            )}
            {serviceLog.sparePartsAndReplacements && (
              <Typography variant="h4">Reservdelar/byten: {serviceLog.sparePartsAndReplacements}</Typography>
            )}
            {serviceLog.recommendedActions && (
              <Typography variant="h4">Rekommenderade åtgärder: {serviceLog.recommendedActions}</Typography>
            )}
            {serviceLog.costAndTime && (
              <Typography variant="h4">Kostnad och tid: {serviceLog.costAndTime}</Typography>
            )}
            {serviceLog.comments && (
              <Typography variant="h4">Kommentarer/Anmärkningar: {serviceLog.comments}</Typography>
            )}
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
              {
                serviceLog && serviceLog.images && serviceLog.images.map((imageUrl, index) => {
                  const isZoomed = zoomedImageIndex === index;

                  return (
                    <Box
                      padding={2}
                      key={index}
                      width={300}
                      sx={{
                        transition: 'transform 0.2s',
                        transform: isZoomed ? 'scale(2)' : 'none',
                        top: isZoomed ? '50%' : '0',
                        left: isZoomed ? '50%' : '0',
                        margin: isZoomed ? '0 auto' : '0',
                        zIndex: isZoomed ? 9999 : 'auto',
                      }}
                      onClick={() => setZoomedImageIndex(isZoomed ? null : index)}
                    >
                      <Card sx={{ padding: 1 }}>
                        <CardContent>
                          {typeof imageUrl === 'string' && (
                            <img
                              src={imageUrl.replace('/images/', `${process.env.REACT_APP_SERVER_URL}/api/images/`)}
                              alt={`Image ${index}`}
                              style={{
                                width: '100%',
                              }}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  );
                })
              }

            </Box>
            <Box display="flex" justifyContent="flex-start" mt={2}>
              <Button component={Link} to="/servicelogs" variant="contained" color="secondary">
                Tillbaka
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </LogTheme>
  );
};

export default ShowServiceLog;